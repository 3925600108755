<template>
  <b-container fluid>
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
              <b-overlay :show="loading">
                <b-row>
                    <b-col>
                        <list-report-head :base-url="trainingElearningServiceBaseUrl" uri="/config/report-head/detail" :org-id="12">
                            {{ $t('elearning_accommodation.manpower_management_details') }}
                        </list-report-head>
                    </b-col>
                </b-row>
                <b-row>
                  <b-col lg="12" sm="12" class="table-form">
                    <b-table-simple bordered>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_config.fiscal_year') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.fiscal_year_bn : manpower.fiscal_year }}</b-td>
                        <b-th style="width:15%" class="text-right" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ $t('org_pro.organization') }}</b-th>
                        <b-th style="width:5%" class="text-center" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">:</b-th>
                        <b-td style="width:25%" class="text-left" v-if="$store.state.Auth.activeRoleId === 1 || $store.state.Auth.authUser.org_id === 1">{{ ($i18n.locale=='bn') ? manpower.org_bn : manpower.org }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{ $t('org_pro.office_type') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.office_bn : manpower.office }}</b-td>
                        <b-th style="width:15%" class="text-right">{{ $t('org_pro.office_namel') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.office_name_bn : manpower.office_name }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_accommodation.employee_name') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.name_bn : manpower.name }}</b-td>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_accommodation.father_name') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.father_name_bn : manpower.father_name }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_accommodation.mobile') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ $i18n.locale === 'bn' ? '০' : '0' }}{{ $n(manpower.mobile_no, { useGrouping: false }) }}</b-td>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_accommodation.nid') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ $n(manpower.nid_no, { useGrouping: false }) }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_config.address') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:20%">{{ ($i18n.locale=='bn') ? manpower.address_bn : manpower.address }}</b-td>
                        <b-th style="width:15%" class="text-right">{{ $t('elearning_config.remarks') }}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">{{ ($i18n.locale=='bn') ? manpower.remark_bn : manpower.remark }}</b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{$t('globalTrans.dob')}}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">
                          {{manpower.date_of_birth | dateFormat}}
                        </b-td>
                        <b-th style="width:15%" class="text-right">{{$t('globalTrans.attachment')}}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">
                          <b v-if="manpower.file">
                            (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'download-attachment?file=uploads/manpower/' + manpower.file" download>{{$t('globalTrans.view_download')}}</a> )
                            <!-- (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/circular-publications/' + circular.upload_guidelines" download>{{$t('globalTrans.view_download')}}</a> ) -->
                          </b>
                        </b-td>
                      </b-tr>
                      <b-tr>
                        <b-th style="width:15%" class="text-right">{{$t('elearning_accommodation.working_field')}}</b-th>
                        <b-th style="width:5%" class="text-center">:</b-th>
                        <b-td style="width:25%" class="text-left">
                          {{manpower.working_field}}
                        </b-td>
                      </b-tr>
                    </b-table-simple>
                  </b-col>
                </b-row>
              </b-overlay>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
</template>
<style scoped>
.table-form th, td{
  padding: 3px !important;
  margin: 1px !important;
  vertical-align: middle !important;
  font-size: 12px;
}
</style>
<script>
import ExportPdf from './export-pdf_details'
import { trainingElearningServiceBaseUrl } from '@/config/api_config'
import ListReportHead from '@/components/custom/ListReportHeadTraining.vue'

export default {
    components: {
      ListReportHead
    },
    name: 'FormLayout',
    props: ['item'],
    created () {
        this.manpower = this.item
    },
    data () {
        return {
          manpower: [],
          trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
          slOffset: 1
        }
    },
    computed: {
      loading () {
        return this.$store.state.commonObj.loading
      }
    },
    methods: {
        pdfExport () {
            const reportTitle = this.$i18n.locale === 'en' ? 'Humnan Resource Management Details' : 'জনবল ব্যাবস্থাপনা বিবরন'
            ExportPdf.exportPdfDetails(trainingElearningServiceBaseUrl, '/config/report-head/detail', 12, reportTitle, this.manpower, this)
        }
    }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
