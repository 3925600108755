import i18n from '@/i18n'
import Store from '@/store'
import ReportHeading from '@/Utils/report-head-traning'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFontsBn from 'pdfmake/build/vfs_fonts_bn'
import pdfFontsEn from 'pdfmake/build/vfs_fonts_en'

const exportPdfDetails = async (baseUrl, uri, orgId, reportTitle, vm, allData) => {
    try {
      Store.commit('mutateCommonProperties', {
        loading: true
      })
      if (i18n.locale === 'bn') {
        pdfMake.vfs = pdfFontsBn.pdfMake.vfs
      } else {
          pdfMake.vfs = pdfFontsEn.pdfMake.vfs
      }
      const reportHeadData = await ReportHeading.getReportHead(baseUrl, uri, orgId)
      const pdfContent = [{
                columns: reportHeadData.reportHeadColumn
            },
            { text: reportHeadData.orgName, style: 'org', alignment: 'center' },
            { text: reportHeadData.projectName, style: 'address', alignment: 'center' },
            { text: reportHeadData.address, style: 'address', alignment: 'center' },
            { text: reportTitle, style: 'hh', alignment: 'center' }
        ]
        const allRows = [
          [
            { text: vm.$t('globalTrans.sl_no'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.fiscal_year'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_config.organization'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('globalTrans.name'), style: 'th', alignment: 'center', bold: true },
            { text: vm.$t('elearning_accommodation.mobile'), style: 'th', alignment: 'center', bold: true }
          ]
        ]
        allData.forEach((info, index) => {
          allRows.push([
            { text: vm.$n(index + 1), alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.fiscal_year_bn : info.fiscal_year, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.org_bn : info.org, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.name_bn : info.name, alignment: 'center', style: 'td' },
            { text: (i18n.locale === 'bn') ? info.mobile_no : info.mobile_no, alignment: 'center', style: 'td' }
          ])
        })
        pdfContent.push({
          table: {
            headerRows: 1,
            widths: ['10%', '15%', '35%', '20%', '20%'],
            body: allRows
          }
        })
      // const waterMarkText = i18n.locale === 'bn' ? 'কৃষি মন্ত্রণালয়' : 'Ministry of Agriculture'
      var docDefinition = {
        content: pdfContent,
        pageSize: 'A4',
        pageOrientation: 'Portrait',
        // watermark: { text: waterMarkText, color: 'blue', opacity: 0.1, bold: true, italics: false },
        styles: {
          th: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          header: {
              fontSize: 16,
              bold: true,
              margin: [0, 0, 0, 0]
          },
          org: {
              fontSize: 13,
              bold: true,
              margin: [0, -25, 10, 5]
          },
          address: {
              fontSize: 11,
              margin: [0, 0, 0, 5]
          },
          hh: {
              fontSize: 13,
              bold: true,
              margin: [10, 10, 25, 20]
          },
          td: {
            fontSize: 10,
            margin: [1, 1, 1, 1]
          },
          header2: {
            fontSize: 14,
            margin: [0, 10, 0, 20]
          },
          krishi: {
            fontSize: 15,
            margin: [0, 1, 0, 25],
            alignment: 'center'
          }
        }
      }
      pdfMake.createPdf(docDefinition, null, null, null).print()
    } catch (error) {
      if (error) {}
    }
    Store.commit('mutateCommonProperties', {
      loading: false
    })
}
export default {
  exportPdfDetails
}
